import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { window } from "browser-monads";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";

// Styles
import * as styles from "../styles/pages/markdown.module.css";

export default function Template({ data, location }) {
  const { t } = useTranslation();
  const { markdownRemark } = data;
  const { frontmatter, html, headings } = markdownRemark;
  const [activeLink, setActiveLink] = React.useState(null);
  const [processedHtml, setProcessedHtml] = React.useState(html);

  const sidebar = {};
  let currentHeading = null;
  let currentSubHeading = null;

  // First pass: collect headings and generate IDs
  const headingsWithIds = headings.map((heading, index) => {
    if (heading.depth === 1) {
      currentHeading = heading.value;
      currentSubHeading = null;
    } else if (heading.depth === 2) {
      currentSubHeading = heading.value;
    }

    const headingId =
      heading.depth === 3
        ? `${currentHeading
            ?.toLowerCase()
            .replace(/ /g, "-")}-${currentSubHeading
            ?.toLowerCase()
            .replace(/ /g, "-")}-${heading.value
            .toLowerCase()
            .replace(/ /g, "-")}`
        : heading.value.toLowerCase().replace(/ /g, "-");

    return {
      ...heading,
      id: headingId,
      parentHeading: currentHeading,
      parentSubHeading: currentSubHeading,
    };
  });

  // Second pass: build sidebar structure
  headingsWithIds.forEach((heading) => {
    if (heading.depth === 1) {
      sidebar[heading.value] = {};
      currentHeading = heading.value;
      currentSubHeading = null;
    } else if (heading.depth === 2) {
      sidebar[currentHeading][heading.value] = [];
      currentSubHeading = heading.value;
    } else if (heading.depth === 3) {
      sidebar[currentHeading][currentSubHeading].push({
        value: heading.value,
        id: heading.id,
      });
    }
  });

  // Process HTML to add IDs to headings
  useEffect(() => {
    if (html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      let currentH1 = null;
      let currentH2 = null;

      // Add IDs to all headings
      doc.querySelectorAll("h1, h2, h3").forEach((heading) => {
        const headingText = heading.textContent;

        if (heading.tagName === "H1") {
          currentH1 = headingText;
        } else if (heading.tagName === "H2") {
          currentH2 = headingText;
        } else if (heading.tagName === "H3") {
          const id = `${currentH1?.toLowerCase().replace(/ /g, "-")}-${currentH2
            ?.toLowerCase()
            .replace(/ /g, "-")}-${headingText
            .toLowerCase()
            .replace(/ /g, "-")}`;
          heading.id = id;
        }
      });

      setProcessedHtml(doc.body.innerHTML);
    }
  }, [html]);

  // scroll spy logic
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 120;

      const sections = document.querySelectorAll("h3[id]");
      const links = document.querySelectorAll('a[href^="#"]');

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        const sectionId = section.getAttribute("id");

        if (
          scrollPosition >= sectionTop &&
          scrollPosition <= sectionTop + sectionHeight
        ) {
          links.forEach((link) => {
            link.classList.remove(styles.isActive);
            if (link.getAttribute("href") === `#${sectionId}`) {
              link.classList.add(styles.isActive);
              setActiveLink(sectionId);
              window.history.replaceState(null, null, `#${sectionId}`);
            }
          });
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (activeLink) {
      const allLinks = document.querySelectorAll('a[href^="#"]');
      const activeLinkElement = Array.from(allLinks).find((link) =>
        link.classList.contains(styles.isActive)
      );

      const sidebarWrapper = document.getElementById("sidebarWrapper");

      if (activeLinkElement) {
        setTimeout(() => {
          sidebarWrapper.scrollTo({
            top: activeLinkElement.offsetTop - 100,
          });
        }, 200);
      }
    }
  }, [activeLink]);

  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const links = document.querySelectorAll('a[href^="#"]');
      links.forEach((link) => {
        link.classList.remove(styles.isActive);
      });
      e.target.classList.add(styles.isActive);

      window.scrollTo({
        top: targetElement.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <Layout hasNavbarOnlyWhite>
      <div className={`${styles.sectionContainer} sectionContainer`}>
        <aside className={`${styles.aside}`} id="sidebarWrapper">
          {Object.keys(sidebar).map((heading) => (
            <div className={`${styles.aside__section}`} key={heading}>
              <h2>{heading}</h2>
              <ul>
                {Object.keys(sidebar[heading]).map((subHeading) => (
                  <div
                    className={`${styles.aside__subsection}`}
                    key={subHeading}
                  >
                    <h2>{subHeading}</h2>
                    <ul>
                      {sidebar[heading][subHeading].map((section) => (
                        <li key={section.id}>
                          <a
                            onClick={handleClick}
                            href={`#${section.id}`}
                            id={`${section.id}Link`}
                          >
                            {section.value}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </aside>
        <div id="contentCard" className={`${styles.content}`}>
          <p className={styles.lastUpdate}>
            {t("last_update")}: {frontmatter.date}
          </p>
          <div
            id="content_wrapper"
            className={`${styles.markdown_style}`}
            dangerouslySetInnerHTML={{ __html: processedHtml }}
          />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      headings {
        depth
        value
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;

export const Head = ({
  pageContext,
  data: {
    markdownRemark: {
      frontmatter: { title },
    },
  },
}) => {
  const isArabic = pageContext.i18n.language === "ar";
  return <Seo isArabic={isArabic} title={title} />;
};
